<template>
  <div
    :id="data.component_id"
    class="z-0"
    :class="{
      'bg-gradient-to-b from-main-blue to-second-blue-900 text-white':
        data.style?.value === 'blue',
    }"
  >
    <!-- SPECIAL TITLE -->
    <ContainerX
      v-if="data.heading && data.style?.value === 'blue'"
      class="flex justify-center pb-20 text-center sm:pb-24 xl:pb-28"
    >
      <h2
        class="font-sans-heavy-900 w-full max-w-title pt-12 text-2xl sm:pb-14 sm:pt-16 sm:text-3xl xl:-mb-9 xl:pb-0 3xl:pt-40"
        v-html="$replaceHtmlTags(data.heading)"
      />
    </ContainerX>
    <SectionY
      :padding="data.padding?.value"
      :class="[
        {
          ' ': data.heading && data.style?.value === 'blue',
        },
        data.padding?.value === 'none' ? 'py-0' : '',
      ]"
    >
      <!-- TITLE -->
      <h2
        v-if="data.heading && data.style?.value !== 'blue'"
        class="default-component-title mx-auto mb-14 xl:mb-20"
        v-html="$replaceHtmlTags(data.heading)"
      />
      <ContainerX class="relative space-y-20 sm:space-y-24 xl:space-y-28">
        <!-- TEASERS -->
        <div
          v-for="(teaser, index) in data.teasers"
          :key="index"
          :ref="`teaser-${index}`"
          class="teaser"
        >
          <TeaserTextAndImage
            :key="index"
            :index="index"
            :teaser="teaser"
            :total-amount-teasers="data.teasers.length"
            :is-blue="data.style?.value === 'blue'"
            class="teaser-content"
            @next="clickNext(index + 1)"
          />
        </div>
      </ContainerX>
    </SectionY>

    <!-- STYLE AND SUCH -->
    <div v-if="data.style?.value === 'blue'" class="aspect-[420/100] w-full" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      ctx: null,
      timelines: [],
    }
  },

  mounted() {
    if (this.data.style?.value !== 'blue') {
      return
    }
  },

  mounted() {
    if (this.data.style?.value !== 'blue') {
      return
    }

    this.$gsap.delayedCall(0.5, () => {
      this.initAnimation()
    })
  },

  beforeUnmount() {
    if (this.ctx) {
      this.ctx.revert()
    }
  },

  methods: {
    clickNext(index) {
      const nextTeaser = this.$refs[`teaser-${index}`]

      if (!Array.isArray(nextTeaser) || !nextTeaser.length) {
        return
      }

      const scrollPosition = window.scrollY || window.pageYOffset
      let scrollToPosition = 0

      const elementPosition = nextTeaser[0]?.getBoundingClientRect()
      scrollToPosition = elementPosition.top + scrollPosition + 1

      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      })
    },

    initAnimation() {
      const slides = this.data.teasers.length
      const mm = this.$gsap.matchMedia()

      mm.add('(min-width: 1024px)', () => {
        this.ctx = this.$gsap.context((self) => {
          const panels = self.selector('.teaser')

          panels.forEach((panel, i) => {
            const teaserContentEL = panel.querySelector('.teaser-content')

            if (i < slides - 1) {
              const timeline = this.$gsap
                .timeline({
                  scrollTrigger: {
                    trigger: panel,
                    start: 'top 63px',
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                  },
                })
                .fromTo(
                  panel,
                  { y: 0, rotate: 0, opacity: 1 },
                  { y: '0%', rotateX: '0deg', opacity: 0.1 }
                )
                .to(
                  teaserContentEL.querySelector('.teaser-text'),
                  { y: '-50%' },
                  0
                )
                .to(panel, { opacity: 0 })

              this.timelines.push(timeline)
            }
          })
        }, this.$el)
      })
    },
  },
}
</script>
